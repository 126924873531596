import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Col,
  Input,
  Row,
  Button,
  FormGroup,
  UncontrolledAlert,
  Table,
} from "reactstrap";
import Swal from "sweetalert2";

import AuthService from "../../../../services/User/AuthService";
import UtiitslPANCardService from "../../../../services/User/UtiitslPANCardService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "../../../../store/loading-context";
import OrderReviewDialog from "./OrderReviewDialog";

const BuyCoupon = (props) => {
  const loadingCtx = useContext(LoadingContext);

  const formRef = useRef();
  const quantityRef = useRef();

  const [userId, setUserId] = useState();

  const [data, setData] = useState();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const userId = AuthService.getUserID();
    setUserId(userId);
  };

  const submitHandler = async () => {
    const quantity = quantityRef.current.value;

    if (!quantity || isNaN(quantity)) {
      Swal.fire("Opps!", "Invalid Quantity", "error");
      return;
    }

    // if (quantity < 5) {
    //   Swal.fire("Opps!", "The quantity should be 5 or more", "error");
    //   return;
    // }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await UtiitslPANCardService.fetchCommercial(
        token,
        quantity
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setData(responseData.data);
        setOpen(true);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div className="justify-content-center mt-4 mb-2">
      <div sm={12}>
        <div className="row">
          <div className="col-md-6">
            <div class="alert alert-info alert-dismissible">
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              <h5>
                <i class="icon fas fa-info"></i> Important Note!
              </h5>
              Please note that UTI coupons will be credited to your account
              within 30 mins to 1 hour.
            </div>

            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                value={props.agentId ? props.agentId : ""}
                disabled
              />
            </div>
            <form ref={formRef}>
              <div className="mb-3">
                <input
                  ref={quantityRef}
                  placeholder="Quantity (Number of Coupons)"
                  type="text"
                  className="form-control"
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  }}
                  maxLength={5}
                />
              </div>
              <div className="text-center plan-btn mt-2 mb-2">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={submitHandler}
                >
                  Buy Coupon Now
                </button>
              </div>
            </form>
          </div>

          <div className="col-md-6">
            <div className="table-responsive">
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>PSA ID</td>
                    <td>:</td>
                    <td>{props.agentId ? props.agentId : ""}</td>
                  </tr>

                  <tr>
                    <td>DEFAULT PASSWORD</td>
                    <td>:</td>
                    <td>{props.agentId ? props.agentId : ""}</td>
                  </tr>

                  <tr>
                    <td>LOGIN</td>
                    <td>:</td>
                    <td>
                      <button
                        className="btn btn-outline-primary"
                        outline
                        onClick={() =>
                          window.open(
                            "https://www.psaonline.utiitsl.com/psapanservices/forms/login.html/loginHome",
                            "_blank"
                          )
                        }
                      >
                        PSA Login
                      </button>
                    </td>
                  </tr>

                  <tr>
                    <td>PAN Status</td>
                    <td>:</td>
                    <td>
                      <button
                        className="btn btn-outline-primary"
                        outline
                        onClick={() =>
                          window.open(
                            "https://www.trackpan.utiitsl.com/PANONLINE/trackApp",
                            "_blank"
                          )
                        }
                      >
                        PAN Status
                      </button>
                    </td>
                  </tr>

                  <tr>
                    <td>e-PAN Download</td>
                    <td>:</td>
                    <td>
                      <button
                        className="btn btn-outline-primary"
                        outline
                        onClick={() =>
                          window.open(
                            "https://www.pan.utiitsl.com/PAN_ONLINE/ePANCard",
                            "_blank"
                          )
                        }
                      >
                        e-PAN Download
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <OrderReviewDialog
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
        data={data}
        formRef={formRef}
        quantity={open ? quantityRef.current.value : ""}
      />
    </div>
  );
};

export default BuyCoupon;
