import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import "./style.css";

const RechargeServicesBox = () => {
  const history = useHistory();

  const [services, setServices] = useState([
    {
      name: "Mobile Recharge",
      image: "/images/prepaid-icon.png",
      bgImage: "/images/mobile-recharge-card-bg.png",
      href: "/user/recharge/prepaid",
    },
    {
      name: "DTH Recharge",
      image: "/images/dth-recharge-icon.png",
      bgImage: "/images/dth-recharge-card-bg.png",
      href: "/user/recharge/dth",
    },
    {
      name: "Google Play Recharge",
      image: "/images/google_play_icon-3.png",
      bgImage: "/images/google-play-card-bg.png",
      href: "/user/recharge/google_play",
    },
    {
      name: "Fastag",
      image: "/images/fastag-icon.png",
      href: "/user/bill_payment/Fastag",
    },
    {
      name: "Electricity",
      image: "/images/electricity-icon.png",
      href: "/user/bill_payment/Electricity",
    },
    {
      name: "Postpaid",
      image: "/images/prepaid-icon.png",
      href: "/user/bill_payment/Postpaid",
    },
    {
      name: "EMI",
      image: "/images/emi-icon.png",
      href: "/user/bill_payment/EMI",
    },
    {
      name: "Gas Cylinder",
      image: "/images/gas-icon.png",
      href: "/user/bill_payment/LPG Booking",
    },
    {
      name: "Landline",
      image: "/images/landline-icon.png",
      href: "/user/bill_payment/Landline",
    },
    {
      name: "Cable TV",
      image: "/images/cable-tv-icon.png",
      href: "/user/bill_payment/Cable TV",
    },
    {
      name: "Piped Gas",
      image: "/images/gas-icon.png",
      href: "/user/bill_payment/Gas",
    },
    {
      name: "Broadband",
      image: "/images/broadband-icon.png",
      href: "/user/bill_payment/Broadband",
    },
    {
      name: "Insurance",
      image: "/images/insurance-icon.png",
      href: "/user/bill_payment/Insurance",
    },
    {
      name: "Water",
      image: "/images/water-icon.png",
      href: "/user/bill_payment/Water",
    },
  ]);

  const navigate = (path) => {
    history.push(path);
  };

  return (
    <React.Fragment>
      <div className="dash-serviceBoxTitle">Recharge & Utility Payments</div>
      <div className="row pt-2">
        {services.map((value, key) => (
          <div className="col-sm-2">
            <div className="card">
              <div
                className="dash-serviceBox"
                onClick={() => {
                  navigate(value.href);
                }}
              >
                <div className="service-image">
                  <a href>
                    {/* rounded-circle */}
                    <img
                      className="img-thumbnail rounded-circle avatar-md"
                      alt="logo"
                      src={value.image}
                    />
                  </a>
                </div>
                <div className="service-content">
                  <h6 style={{ color: "black" }}>{value.name}</h6>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

export default RechargeServicesBox;
