import common from "../../utill/Common";

class PgService {
  report = async (
    token,
    from_date,
    to_date,
    gateway_name,
    use_case,
    status,
    user_id,
    search_text,
    page,
    size
  ) => {
    const url = common.baseUrl + "admin/pg/report";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        from_date,
        to_date,
        gateway_name,
        use_case,
        search_text,
        status,
        user_id,
        page,
        size,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  summary = async (
    token,
    from_date,
    to_date,
    gateway_name,
    use_case,
    user_id
  ) => {
    const url = common.baseUrl + "admin/pg/summary";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        from_date,
        to_date,
        gateway_name,
        use_case,
        user_id,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  checkStatus = async (token, order_id) => {
    const url = common.baseUrl + "admin/pg/check_status";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        order_id,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PgService();
