import React, { useRef, useContext, useState, useEffect } from "react";
import Swal from "sweetalert2";
import ReactSelect from "react-select";
import BarLoader from "react-spinners/BarLoader";

import Templete from "../../Templete/Templete";

import AuthService from "../../../../services/User/AuthService";
import RechargeService from "../../../../services/User/RechargeService";
import LoadingContext from "../../../../store/loading-context";
import HttpError from "../../../../utill/HttpError";
import { useHistory } from "react-router-dom";

import OrderReviewDialog from "./OrderReviewDialog";
import ViewPlansDialog from "./ViewPlansDialog";
import RecentTransactions from "./RecentTransactions";

import "./style.css";
import RechargePlan from "./RechargePlan";

const Recharge = (props) => {
  const history = useHistory();

  const loadingCtx = useContext(LoadingContext);

  const type = props.type ? props.type : props.match.params.type;

  const formRef = useRef();

  const [isBackup, setIsBackup] = useState(false);
  const [number, setNumber] = useState();
  const [operator, setOperator] = useState();
  const [operatorName, setOperatorName] = useState();
  const [operatorId, setOperatorId] = useState();
  const [circle, setCircle] = useState();
  const [amount, setAmount] = useState();

  const [open, setOpen] = useState(false);

  const submitHandler = async () => {
    if (!number) {
      Swal.fire("Opps!", "Invalid Recharge Number", "error");
      return;
    }

    if (!operator) {
      Swal.fire("Opps!", "Invalid Operator", "error");
      return;
    }

    if (!amount) {
      Swal.fire("Opps!", "Invalid Amount", "error");
      return;
    }

    setOpen(true);
  };

  const [fetchOperatorLoading, setFetchOperatorLoading] = useState(false);

  const fetchOperator = async (mobile) => {
    if (mobile.length < 10) {
      return;
    }

    setFetchOperatorLoading(true);

    try {
      const token = AuthService.getAccessToken();

      const responseData = await RechargeService.fetchOperator(token, mobile);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        let obj = operators.find(
          (o) => o.value === responseData.data.operator_id
        );

        setOperator(obj);
        setCircle(responseData.data.circle);
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    setFetchOperatorLoading(false);
  };

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    recentTransactions();
  }, []);

  const recentTransactions = async () => {
    const token = AuthService.getAccessToken();

    setLoading(true);

    RechargeService.recentTransactions(token)
      .then((responseData) => {
        if (responseData.status !== "SUCCESS") {
          throw new Error(responseData.message);
        }

        const dataResult = responseData.data.result;
        setData(dataResult);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const [prepaidOperator, setPrepaidOperator] = useState([
    { value: 1, label: "Airtel", image: "/images/airtel_logo.png" },
    { value: 2, label: "BSNL Topup", image: "/images/bsnl_logo.png" },
    { value: 3, label: "BSNL Special", image: "/images/bsnl_logo.png" },
    { value: 4, label: "JIO", image: "/images/jio_logo.png" },
    { value: 5, label: "Vi", image: "/images/vi_logo.png" },
    //  { value: 47, label: "Google Play", image: "images/vi_logo.png" },
  ]);

  const [dthOperator, setDthOperator] = useState([
    {
      value: 6,
      label: "Airtel Digital TV",
      image: "/images/airtel_digital_tv_logo.png",
    },
    { value: 7, label: "Dish TV", image: "/images/dish_tv_logo.png" },
    { value: 8, label: "Sun Direct", image: "/images/sun_direct_logo.png" },
    { value: 9, label: "Tata Sky", image: "/images/tata_sky_logo.png" },
    {
      value: 10,
      label: "Videocon D2H",
      image: "/images/videocon_d2h_logo.png",
    },
  ]);

  const [googlePlayOperators, setGooglePlayOperators] = useState([
    { value: 47, label: "Google Play", image: "/images/google_play_icon.png" },
  ]);

  const [operators, setOperators] = useState([]);

  useEffect(() => {
    handleSetOperator();
  }, [type]);

  const handleSetOperator = () => {
    if (type == "prepaid") {
      setSelectedService("Mobile");
      setOperators(prepaidOperator);
    }

    if (type == "dth") {
      setSelectedService("DTH");
      setOperators(dthOperator);
    }

    if (type == "google_play") {
      setSelectedService("Google Play");
      setOperators(googlePlayOperators);
    }

    // setNumber();
    setOperator("");
    // setAmount();
    // setCircle();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const [openPlans, setOpenPlans] = useState(false);
  const [plansData, setPlansData] = useState();

  const handleOpenPlans = () => {
    setPlansData();
    setOpenPlans(true);
  };

  const handleClosePlans = () => {
    setOpenPlans(false);
  };

  const handleSetAmount = (value) => {
    setAmount(value);
  };

  const handleSetPlansData = (value) => {
    setPlansData(value);
  };

  const [userId, setUserId] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (!user) {
      return;
    }

    setUserId(user.user_id);
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 50,
      minHeight: 50,
    }),
  };

  const [selectedService, setSelectedService] = useState();

  const [services, setServices] = useState([
    {
      name: "Mobile",
      image: "/images/prepaid-icon-2.png",
      href: "/user/recharge/prepaid",
    },
    {
      name: "DTH",
      image: "/images/dth-recharge-icon-2.png",
      href: "/user/recharge/dth",
    },
    {
      name: "Google Play",
      image: "/images/google_play_icon-3.png",
      href: "/user/recharge/google_play",
    },
  ]);

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Recharge">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6 ">
                <div
                  class="card card-default "
                  // style={{ height: "525px", overflow: "auto", width: "100%" }}
                >
                  <div class="card-header">
                    <h3 class="card-title">Recharge</h3>
                    <div class="card-tools">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                          history.push("/user/recharge_report");
                        }}
                      >
                        Report
                      </button>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-10 mx-auto">
                      <form ref={formRef} autoComplete="off">
                        <div class="card-body">
                          <div className="row">
                            {services.map((value, key) => (
                              <div className="col-sm" key={key}>
                                <div
                                  className="serviceBox"
                                  onClick={() => {
                                    history.push(value.href);
                                    setSelectedService(value.name);
                                  }}
                                >
                                  <div className="service-image active">
                                    <a href>
                                      <img
                                        className="img-thumbnail rounded-circle avatar-md"
                                        alt="logo"
                                        src={value.image}
                                      />
                                    </a>
                                  </div>
                                  <div className="service-content">
                                    {selectedService === value.name && (
                                      <strong>
                                        <h5>{value.name}</h5>
                                      </strong>
                                    )}
                                    {selectedService !== value.name && (
                                      <h6>{value.name}</h6>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>

                          <div class="form-group row">
                            <div class="col-sm">
                              <input
                                type="text"
                                class="form-control form-control-lg"
                                placeholder="Recharge Number"
                                maxLength={15}
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                }}
                                defaultValue={number}
                                onChange={(e, v) => {
                                  if (type == "prepaid") {
                                    fetchOperator(e.target.value);
                                  }
                                  setNumber(e.target.value);
                                }}
                                style={{ fontWeight: "bold" }}
                              />
                            </div>
                          </div>

                          <div className="mb-2">
                            <BarLoader
                              color="#42ba96"
                              loading={fetchOperatorLoading}
                              width="100%"
                            />
                          </div>

                          <div class="form-group row">
                            <div class="col-sm">
                              <ReactSelect
                                styles={customStyles}
                                options={operators}
                                onChange={(e) => {
                                  setOperator(e);
                                }}
                                value={operator}
                                // defaultValue={{
                                //   value: 1,
                                //   label: "Airtel",
                                //   image: "images/airtel_logo.png",
                                // }}
                                formatOptionLabel={(operator) => (
                                  <div>
                                    <img
                                      src={operator.image}
                                      alt="logo-image"
                                      height={30}
                                    />
                                    <span style={{ paddingLeft: "5px" }}>
                                      {operator.label}
                                    </span>
                                  </div>
                                )}
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <div class="col-sm">
                              <input
                                type="text"
                                class="form-control form-control-lg"
                                placeholder="Amount"
                                maxLength={5}
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                }}
                                defaultValue={amount}
                                onChange={(e, v) => {
                                  setAmount(e.target.value);
                                }}
                                style={{ fontWeight: "bold" }}
                              />
                              {/* {type === "prepaid" && operator && (
                                <div
                                  className="mt-2"
                                  style={{ textAlign: "right" }}
                                >
                                  <span
                                    className="badge badge-primary"
                                    onClick={handleOpenPlans}
                                  >
                                    Check Plans
                                  </span>
                                </div>
                              )} */}
                            </div>
                          </div>
                          {/* 
                          <div class="row">
                            <div class="col-sm-6">
                              <div class="form-group clearfix">
                                <div class="icheck-primary d-inline">
                                  <input
                                    type="checkbox"
                                    id="checkboxPrimary1"
                                    checked={isBackup}
                                    onClick={() => {
                                      setIsBackup(!isBackup);
                                    }}
                                  />
                                  <label for="checkboxPrimary1">
                                    Backup Recharge
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div> */}

                          <div class="text-center mb-3">
                            <button
                              type="button"
                              class="btn btn-primary"
                              onClick={submitHandler}
                            >
                              Recharge Now
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <RechargePlan
                  open={openPlans}
                  handleClose={handleClosePlans}
                  handleOpen={handleOpenPlans}
                  circle={circle}
                  setCircle={setCircle}
                  operator={operator}
                  setOperator={setOperator}
                  prepaidOperator={prepaidOperator}
                  handleSetAmount={handleSetAmount}
                  handleSetPlansData={handleSetPlansData}
                  plansData={plansData}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <RecentTransactions
                  data={data}
                  loading={loading}
                  recentTransactions={recentTransactions}
                />
              </div>
            </div>
          </div>
        </section>

        <OrderReviewDialog
          open={open}
          handleClose={handleClose}
          handleOpen={handleOpen}
          formRef={formRef}
          userId={userId}
          number={open ? number : ""}
          operator={open ? operator : ""}
          operatorName={open ? operatorName : ""}
          amount={open ? amount : ""}
          isBackup={isBackup}
          recentTransactions={recentTransactions}
        />

        {/* <ViewPlansDialog
          open={openPlans}
          handleClose={handleClosePlans}
          handleOpen={handleOpenPlans}
          circle={circle}
          setCircle={setCircle}
          operator={operator}
          handleSetAmount={handleSetAmount}
          handleSetPlansData={handleSetPlansData}
          plansData={plansData}
        /> */}
      </Templete>
    </React.Fragment>
  );
};

export default Recharge;
