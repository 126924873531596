import React, { useState, useContext } from "react";
import DataTableComponent from "react-data-table-component";

import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import IconButton from "@mui/material/IconButton";
import ViewListIcon from "@mui/icons-material/ViewModule";
import CloseIcon from "@mui/icons-material/Close";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Swal from "sweetalert2";

import Templete from "../Templete/Templete";
import AuthService from "../../../services/Admin/AuthService";
import PgService from "../../../services/Admin/PgService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";
import TxnDetailsDialog from "../Transactions/TxnDetailsDialog";
import ViewLogDialog from "./ViewLogDialog";

import common from "../../../utill/Common";

const PgReport = () => {
  const loadingCtx = useContext(LoadingContext);

  const [fromDate, setFromDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [toDate, setToDate] = useState(new Date().toISOString().slice(0, 10));
  const [status, setStatus] = useState();
  const [gatewayName, setGatewayName] = useState();
  const [useCase, setUseCase] = useState();
  const [userId, setUserId] = useState();

  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState();
  const [count, setCount] = useState();
  const [data, setData] = useState();

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedData, setSelectedData] = useState();

  // useEffect(() => {
  //   // fetchData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []); // page, size, searchText, fromDate, toDate, status, productId

  // useEffect(() => {
  //  // getSummary();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [fromDate, toDate]);

  const fetchData = async () => {
    const token = AuthService.getAccessToken();

    setLoading(true);

    PgService.report(
      token,
      fromDate,
      toDate,
      gatewayName,
      useCase,
      status,
      userId,
      searchText,
      page,
      size
    )
      .then((responseData) => {
        if (responseData.status !== "SUCCESS") {
          throw new Error(responseData.message);
        }

        const dataResult = responseData.data.result;
        setCount(responseData.data.count);
        setData(dataResult);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      name: "User ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.tbl_user.user_id}</span> <br />
          <small>{row.tbl_user.name}</small>
        </div>
      ),
    },
    {
      name: "Gateway Name",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.gateway_name}</span>
        </div>
      ),
    },
    {
      name: "Use Case",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.use_case}</span>
        </div>
      ),
    },
    {
      name: "Amount",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span style={{ fontWeight: "bold", fontSize: "16px" }}>
            ₹{row.amount}
          </span>
        </div>
      ),
    },
    {
      name: "Order ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.order_id}</span>
          <br />
          <small>{row.partner_order_id}</small>
        </div>
      ),
    },
    {
      name: "Status",
      cell: (row) => (
        <div>
          {(() => {
            if (row.status === "SUCCESS") {
              return (
                <h5>
                  <span class="badge badge-success">SUCCESS</span>
                </h5>
              );
            } else if (row.status === "FAILED") {
              return (
                <h5>
                  <span class="badge badge-danger">FAILED</span>
                </h5>
              );
            } else if (row.status === "PENDING") {
              return (
                <h5>
                  <span class="badge badge-warning">PENDING</span>
                </h5>
              );
            } else if (row.status === "REVERSAL") {
              return (
                <h5>
                  <span class="badge badge-danger">REVERSAL</span>
                </h5>
              );
            } else if (row.status === "REFUND") {
              return (
                <h5>
                  <span class="badge badge-danger">FAILED</span>
                </h5>
              );
            }
          })()}
        </div>
      ),
    },
    {
      name: "Date Time",
      cell: (row) => (
        <div>
          <span>{row.created_on}</span>
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <IconButton
            color="primary"
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(event) => {
              handleClick(event);
              setSelectedData(row);
            }}
          >
            <MenuOpenIcon />
          </IconButton>

          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => {
              setSelectedData(row);
              setOpenDialog(true);
            }}
          >
            <ViewListIcon />
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                checkStatusHandler(selectedData.order_id);
              }}
            >
              Check Status
            </MenuItem>
          </Menu>
        </div>
      ),
    },
  ];

  const [isLoading, setIsloading] = useState(false);

  const [summaryData, setSummaryData] = useState();

  const getSummary = async () => {
    setIsloading(true);

    try {
      const token = AuthService.getAccessToken();

      const responseData = await PgService.summary(
        token,
        fromDate,
        toDate,
        gatewayName,
        useCase,
        userId
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setSummaryData(responseData.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    setIsloading(false);
  };

  const checkStatusHandler = async (order_id) => {
    const access_token = AuthService.getAccessToken();

    const url =
      common.baseUrl +
      `admin/pg/check_status?order_id=${order_id}&access_token=${access_token}`;

    window.open(url, "_blank");

    // loadingCtx.startLoading();

    // try {
    //   const token = AuthService.getAccessToken();

    //   const responseData = await PgService.checkStatus(token, order_id);

    //   const status = responseData.status;
    //   const message = responseData.message;

    //   if (status == "SUCCESS") {
    //     const log = responseData.data ? responseData.data.log : null;

    //     Swal.fire({
    //       title: message,
    //       text: log ? JSON.stringify(log) : "null",
    //     });
    //   } else {
    //     Swal.fire("Opps!", message, "error");
    //   }
    // } catch (error) {
    //   const errMsg = HttpError.getError(error);
    //   Swal.fire("Opps!", errMsg, "error");
    // }

    // loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="PG Report">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">PG Report</h3>
                  </div>

                  <div class="card-body">
                    <div class="row mt-2">
                      <div class="col-md-12 mx-auto">
                        <form autoComplete="off">
                          <div class="row">
                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="date"
                                  class="form-control"
                                  placeholder="From Date"
                                  onChange={(e) => {
                                    setFromDate(e.target.value);
                                  }}
                                  defaultValue={fromDate}
                                />
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="date"
                                  class="form-control"
                                  placeholder="To Date"
                                  onChange={(e) => {
                                    setToDate(e.target.value);
                                  }}
                                  defaultValue={toDate}
                                />
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    setStatus(e.target.value);
                                  }}
                                >
                                  <option value="">Select Status</option>
                                  <option value="SUCCESS">SUCCESS</option>
                                  <option value="PENDING">PENDING</option>
                                  <option value="REFUND">FAILED</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    setGatewayName(e.target.value);
                                  }}
                                >
                                  <option value="">Select Gateway Name</option>
                                  <option value="CASHFREE">CASHFREE</option>
                                  <option value="EASEBUZZ">EASEBUZZ</option>
                                  <option value="PAYU">PAYU</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    setUseCase(e.target.value);
                                  }}
                                >
                                  <option value="">Select Use Case</option>
                                  <option value="UPI_TOPUP">UPI_TOPUP</option>
                                  <option value="PG_TOPUP">PG_TOPUP</option>
                                  <option value="PPI_TOPUP">PPI_TOPUP</option>
                                  <option value="KYC_VERIFICATION_CHARGE">
                                    KYC_VERIFICATION_CHARGE
                                  </option>
                                </select>
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="User ID"
                                  onChange={(e) => {
                                    setUserId(e.target.value);
                                  }}
                                />
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Search Text"
                                  onChange={(e) => {
                                    setSearchText(e.target.value);
                                  }}
                                />
                              </div>
                            </div>

                            <div
                              class="col-sm-3"
                              style={{ textAlign: "center" }}
                            >
                              <div class="d-grid gap-2 d-md-flex">
                                <button
                                  type="button"
                                  class="btn btn-primary mr-4"
                                  onClick={() => {
                                    fetchData();
                                  }}
                                >
                                  <i class="fa fa-search"></i> Search
                                </button>

                                <button
                                  type="button"
                                  class="btn btn-secondary mr-4"
                                  onClick={() => {
                                    getSummary();
                                  }}
                                >
                                  <i class="fa fa-search"></i> Fetch Summary
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col-12 col-sm-6 col-md-4">
                        <div class="info-box">
                          <span class="info-box-icon bg-success elevation-1">
                            <i class="fas fa-arrow-right"></i>
                          </span>
                          <div class="info-box-content">
                            <span class="info-box-text">TOTAL SUCCESS</span>
                            <span class="info-box-number">
                              {isLoading && (
                                <div class="spinner-border spinner-border-sm"></div>
                              )}
                              {!isLoading && summaryData
                                ? summaryData.success.rows.length > 0
                                  ? summaryData.success.rows[0].total_amount +
                                    " [" +
                                    summaryData.success.count +
                                    "]"
                                  : "0" + " [" + summaryData.success.count + "]"
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6 col-md-4">
                        <div class="info-box mb-4">
                          <span class="info-box-icon bg-warning elevation-1">
                            <i class="fas fa-arrow-right"></i>
                          </span>
                          <div class="info-box-content">
                            <span class="info-box-text">TOTAL PENDING</span>
                            <span class="info-box-number">
                              {isLoading && (
                                <div class="spinner-border spinner-border-sm"></div>
                              )}
                              {!isLoading && summaryData
                                ? summaryData.pending.rows.length > 0
                                  ? summaryData.pending.rows[0].total_amount +
                                    " [" +
                                    summaryData.pending.count +
                                    "]"
                                  : "0" + " [" + summaryData.pending.count + "]"
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6 col-md-4">
                        <div class="info-box mb-4">
                          <span class="info-box-icon bg-danger elevation-1">
                            <i class="fas fa-arrow-right"></i>
                          </span>
                          <div class="info-box-content">
                            <span class="info-box-text">TOTAL FAILED</span>
                            <span class="info-box-number">
                              {isLoading && (
                                <div class="spinner-border spinner-border-sm"></div>
                              )}
                              {!isLoading && summaryData
                                ? summaryData.failed.rows.length > 0
                                  ? summaryData.failed.rows[0].total_amount +
                                    " [" +
                                    summaryData.failed.count +
                                    "]"
                                  : "0" + " [" + summaryData.failed.count + "]"
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <DataTableComponent
                      noHeader={true}
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      highlightOnHover
                      pagination
                      paginationServer
                      paginationTotalRows={count}
                      paginationPerPage={size}
                      onChangePage={(page) => {
                        setPage(page);
                        fetchData();
                      }}
                      paginationRowsPerPageOptions={[
                        10, 20, 50, 100, 200, 500, 1000,
                      ]}
                      onChangeRowsPerPage={(size) => {
                        setSize(size);
                        fetchData();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>

      <ViewLogDialog
        setOpen={setOpenDialog}
        open={openDialog}
        fData={selectedData}
      />
    </React.Fragment>
  );
};

export default PgReport;
