import common from "../../utill/Common";

class RechargeService {
  transaction = async (token, userId, pin, operator, number, amount, route) => {
    const url = common.baseUrl + "user/recharge/transaction";

    const basicAuth =
      "Basic " + Buffer.from(userId + ":" + pin).toString("base64");

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
        Authorization: basicAuth,
      },
      body: JSON.stringify({ operator, number, amount, route }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  fetchOperator = async (token, mobile) => {
    const url = common.baseUrl + "user/recharge/fetch_operator";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ mobile }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  fetchPlans = async (token, operator, circle) => {
    const url = common.baseUrl + "user/recharge/fetch_plans";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ operator, circle }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  report = async (
    token,
    from_date,
    to_date,
    status,
    product_id,
    search_text,
    page,
    size
  ) => {
    const url = common.baseUrl + "user/recharge/report";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        from_date,
        to_date,
        status,
        product_id,
        search_text,
        page,
        size,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  summary = async (token, from_date, to_date, product_id) => {
    const url = common.baseUrl + "user/recharge/summary";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ from_date, to_date, product_id }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  recentTransactions = async (token) => {
    const url = common.baseUrl + "user/recharge/recent_transactions";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  dispute = async (token, order_id) => {
    const url = common.baseUrl + "user/recharge/dispute";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ order_id }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  discountDetails = async (token) => {
    const url = common.baseUrl + "user/recharge/discount_details";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new RechargeService();
