import React, { useRef, useState, useContext } from "react";
import Swal from "sweetalert2";
import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import CreditService from "../../../services/Admin/CreditService";
import AgentsService from "../../../services/Admin/AgentsService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const AddCreditTransaction = () => {
  const loadingCtx = useContext(LoadingContext);

  const [open, setOpen] = useState(false);
  const [txnType, setTxnType] = useState();
  const [data, setData] = useState();

  const [id, setId] = useState();

  const formRef = useRef();
  const userIdRef = useRef();
  const amountRef = useRef();
  const txnDateRef = useRef();
  const remarkRef = useRef();

  const submitHandler = async () => {
    const amount = amountRef.current.value;
    const txnDate = txnDateRef.current.value;
    const remark = remarkRef.current.value;

    if (!id) {
      Swal.fire("Opps!", "Invalid User", "error");
      return;
    }

    if (!txnType) {
      Swal.fire("Opps!", "Invalid TXN Type", "error");
      return;
    }

    if (!amount) {
      Swal.fire("Opps!", "Invalid Amount", "error");
      return;
    }

    if (!txnDate) {
      Swal.fire("Opps!", "Invalid TXN Date", "error");
      return;
    }

    if (!remark) {
      Swal.fire("Opps!", "Invalid Remark", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await CreditService.addCreditTransaction(
        token,
        id,
        txnType,
        amount,
        txnDate,
        remark
      );
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        formRef.current.reset();
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const fetchHandler = async () => {
    const userId = userIdRef.current.value;

    if (!userId) {
      Swal.fire("Opps!", "Invalid User ID", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await AgentsService.fetchByUserId(token, userId);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setOpen(true);
        setData(responseData.data.result);
        setId(responseData.data.result.id);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Add Credit Transaction">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Add Credit Transaction</h3>
                  </div>

                  <div class="row">
                    <div class="col-md-8 mx-auto">
                      <form ref={formRef} autoComplete="off">
                        <div class="card-body">
                          {!open && (
                            <div class="form-group row">
                              <div class="input-group input-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Enter User ID/Mobile Number"
                                  ref={userIdRef}
                                />
                                <span class="input-group-append">
                                  <button
                                    type="button"
                                    class="btn btn-info btn-flat"
                                    onClick={fetchHandler}
                                  >
                                    Go
                                  </button>
                                </span>
                              </div>
                            </div>
                          )}

                          {open && data && (
                            <div>
                              <div class="form-group row">
                                <label class="col-sm-3 col-form-label">
                                  Name
                                </label>
                                <div class="col-sm-9">
                                  <input
                                    type="text"
                                    class="form-control"
                                    value={
                                      data.name + " (" + data.user_id + ")"
                                    }
                                    readOnly
                                  />
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-sm-3 col-form-label">
                                  TXN Type
                                </label>
                                <div class="col-sm-9">
                                  <select
                                    name="title"
                                    class="form-control"
                                    onChange={(e) => {
                                      setTxnType(e.target.value);
                                    }}
                                  >
                                    <option value="">
                                      ---Select TXN Type---
                                    </option>
                                    <option value="CR">CREDIT</option>
                                    <option value="DR">DEBIT</option>
                                  </select>
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-sm-3 col-form-label">
                                  Amount
                                </label>
                                <div class="col-sm-9">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Amount"
                                    maxLength={10}
                                    ref={amountRef}
                                  />
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-sm-3 col-form-label">
                                  TXN Date
                                </label>
                                <div class="col-sm-9">
                                  <input
                                    type="date"
                                    class="form-control"
                                    placeholder="TXN Date"
                                    ref={txnDateRef}
                                  />
                                </div>
                              </div>

                              <div class="form-group row">
                                <label class="col-sm-3 col-form-label">
                                  Remark
                                </label>
                                <div class="col-sm-9">
                                  <textarea
                                    ref={remarkRef}
                                    className="form-control"
                                    placeholder="Remark"
                                    rows="3"
                                  ></textarea>
                                </div>
                              </div>

                              <div class="card-footer text-center">
                                <button
                                  type="button"
                                  class="btn btn-primary"
                                  onClick={submitHandler}
                                >
                                  Submit
                                </button>
                                <button
                                  type="reset"
                                  class="btn btn-default ml-5"
                                  onClick={() => {
                                    setOpen(!open);
                                  }}
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default AddCreditTransaction;
