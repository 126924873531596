import common from "../../utill/Common";

class BbpsService {
  operatorList = async (token, category) => {
    const url = common.baseUrl + "user/bbps/operator_list";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ category }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  billFetch = async (token, id, operator_id, params) => {
    const url = common.baseUrl + "user/bbps/bill_fetch";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        id,
        operator_id,
        params,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  billPayment = async (token, userId, pin, id, operator_id, params, amount) => {
    const url = common.baseUrl + "user/bbps/bill_payment";

    const basicAuth =
      "Basic " + Buffer.from(userId + ":" + pin).toString("base64");

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
        Authorization: basicAuth,
      },
      body: JSON.stringify({
        id,
        operator_id,
        params,
        amount,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  report = async (
    token,
    from_date,
    to_date,
    status,
    product_id,
    search_text,
    page,
    size
  ) => {
    const url = common.baseUrl + "user/bbps/report";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        from_date,
        to_date,
        status,
        product_id,
        search_text,
        page,
        size,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new BbpsService();
