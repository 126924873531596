import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";
import { Redirect } from "react-router-dom";
import AdminAuthService from "../services/Admin/AuthService";

//Layouts
import NonAuthLayout from "../layouts/NonAuthLayout";

//routes
import {
  adminAuthProtectedRoutes,
  authProtectedRoutes,
  publicRoutes,
} from "./allRoutes"; // authProtectedKycRoutes
import {
  AuthProtected,
  AdminAuthProtected,
  AccessRoute,
} from "./AuthProtected";
import Login from "../pages/User/Login/Login";

const Index = () => {
  const [isUser, setIsUser] = React.useState();
  const [isAdmin, setIsAdmin] = React.useState();

  const [xpublicRoutes, setPublicRoutes] = useState([]);

  // const [availableAuthRoutesPath, setAvailableAuthRoutesPath] = useState(
  //   authProtectedRoutes.map((r) => r.path)
  // );
  // const [availableAdminAuthRoutesPath, setAvailableAdminAuthRoutesPath] =
  //   useState(adminAuthProtectedRoutes.map((r) => r.path));

  const availablePublicRoutesPaths = publicRoutes.map((r) => r.path);
  const availableAuthRoutesPath = authProtectedRoutes.map((r) => r.path);
  const availableAdminAuthRoutesPath = adminAuthProtectedRoutes.map(
    (r) => r.path
  );

  React.useEffect(() => {
    getData();
    console.log(JSON.stringify(xpublicRoutes));
  }, [isUser]);

  const getData = () => {
    const admin = localStorage.getItem("admin_access_token");
    let user = localStorage.getItem("access_token");

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const authorization = params.get("authorization");

    if (authorization) {
      user = true;
    }

    if (admin) {
      setIsAdmin(true);
    }

    if (user || admin) {
      const _publicRoutes = publicRoutes.filter(function (item) {
        return item.path !== "/";
      });
      setPublicRoutes(_publicRoutes);

      let index = authProtectedRoutes.findIndex((item) => item.path === "/");

      // Check if the object with the specified property value exists in the array
      if (index === -1) {
        // If not found, push a new object with the desired properties
        // authProtectedRoutes.push({
        //   path: "/",
        //   exact: true,
        //   component: () => <Redirect to="/login" />,
        // });
      }

      // const _authProtectedRoutes = authProtectedRoutes.filter(function (item) {
      //   return item.path !== "/";
      // });

      // alert(JSON.stringify(authProtectedRoutes));
      // authProtectedRoutes.push({
      //   path: "/",
      //   exact: true,
      //   component: () => <Redirect to="/login" />,
      // });

      setIsUser(true);
    } else {
      let tempPublicRoutes = publicRoutes;
      tempPublicRoutes.push({ path: "/", exact: true, component: Login });

      setPublicRoutes(tempPublicRoutes);
    }

    // if (user) {
    //   setIsUser(true);
    // }
  };

  return (
    <React.Fragment>
      <Switch>
        <Route path={availablePublicRoutesPaths}>
          <NonAuthLayout>
            <Switch>
              {xpublicRoutes.map((route, idx) => (
                <Route
                  path={route.path}
                  component={route.component}
                  key={idx}
                  exact={true}
                />
              ))}
            </Switch>
          </NonAuthLayout>
        </Route>

        {isUser && (
          <Route path={availableAuthRoutesPath}>
            <AuthProtected>
              <Switch>
                {authProtectedRoutes.map((route, idx) => (
                  <AccessRoute
                    path={route.path}
                    component={route.component}
                    key={idx}
                    exact={true}
                  />
                ))}

                {isAdmin &&
                  adminAuthProtectedRoutes.map((route, idx) => (
                    <AccessRoute
                      path={route.path}
                      component={route.component}
                      key={idx}
                      exact={true}
                    />
                  ))}
              </Switch>
            </AuthProtected>
          </Route>
        )}

        {isAdmin && (
          <Route path={availableAdminAuthRoutesPath}>
            <AdminAuthProtected>
              <Switch>
                {adminAuthProtectedRoutes.map((route, idx) => (
                  <AccessRoute
                    path={route.path}
                    component={route.component}
                    key={idx}
                    exact={true}
                  />
                ))}

                {isUser &&
                  authProtectedRoutes.map((route, idx) => (
                    <AccessRoute
                      path={route.path}
                      component={route.component}
                      key={idx}
                      exact={true}
                    />
                  ))}
              </Switch>
            </AdminAuthProtected>
          </Route>
        )}
      </Switch>
    </React.Fragment>
  );
};

export default Index;
