import React, { useRef, useContext, useState, useEffect } from "react";
import Swal from "sweetalert2";
import ReactSelect from "react-select";
import BarLoader from "react-spinners/BarLoader";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import Templete from "../../Templete/Templete";

import AuthService from "../../../../services/User/AuthService";
import BbpsService from "../../../../services/User/BbpsService";
import RechargeService from "../../../../services/User/RechargeService";
import LoadingContext from "../../../../store/loading-context";
import HttpError from "../../../../utill/HttpError";
import { useHistory } from "react-router-dom";
import BillDetailsDialog from "./BillDetailsDialog";
import BillReceiptDialog from "./BillReceiptDialog";

// import OrderReviewDialog from "./OrderReviewDialog";
// import ViewPlansDialog from "./ViewPlansDialog";
// import RecentTransactions from "./RecentTransactions";

// import "./style.css";

const BillPayment = (props) => {
  const history = useHistory();

  const loadingCtx = useContext(LoadingContext);

  const category = props.category
    ? props.category
    : props.match.params.category;

  const formRef = useRef();
  const [amount, setAmount] = useState();

  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [fetchOperatorLoading, setFetchOperatorLoading] = useState();

  useEffect(() => {
    fetchOperatorList();
    recentTransactions();
  }, []);

  const [selectedOperator, setSelectedOperator] = useState();
  const [operatorList, setOperatorList] = useState([]);

  const fetchOperatorList = async () => {
    setFetchOperatorLoading(true);

    try {
      const token = AuthService.getAccessToken();

      const responseData = await BbpsService.operatorList(token, category);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        let operatorList = responseData.data;
        setOperatorList(operatorList);
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    setFetchOperatorLoading(false);
  };

  const [billData, setBillData] = useState();

  const billFetch = async () => {
    if (!selectedOperator) {
      Swal.fire("Opps!", "Please Select Operator", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await BbpsService.billFetch(
        token,
        selectedOperator.id,
        selectedOperator.operator_id,
        selectedOperator.params
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setBillData(responseData.data);
        setOpen(true);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const billPaymentHandler = async () => {
    if (!billData) {
      Swal.fire("Opps!", "Bill Fetched Required", "error");
      return;
    }

    var _amount;

    if (billData["accept_part_pay"] == true) {
      _amount = amount;
    } else {
      _amount = billData["bill_net_amount"];
    }

    if (_amount == null || amount == "") {
      Swal.fire("Opps!", "Invalid Amount", "error");
      return;
    }

    if (pin == null || pin == "") {
      Swal.fire("Opps!", "Invalid PIN", "error");
      return;
    }

    setOpen(false);
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await BbpsService.billPayment(
        token,
        userId,
        pin,
        selectedOperator.id,
        selectedOperator.operator_id,
        selectedOperator.params,
        amount
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS" || status === "PENDING") {
        //  Swal.fire("Success!", message, "success");
        setReceiptData(responseData.data);
        setOpenReceipt(true);
      } else {
        setOpen(true);
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      setOpen(true);
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const recentTransactions = async () => {
    const token = AuthService.getAccessToken();

    setLoading(true);

    RechargeService.recentTransactions(token)
      .then((responseData) => {
        if (responseData.status !== "SUCCESS") {
          throw new Error(responseData.message);
        }

        const dataResult = responseData.data.result;
        //  setData(dataResult);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const [openReceipt, setOpenReceipt] = useState(false);
  const [receiptData, setReceiptData] = useState();

  const handleOpenReceipt = () => {
    setOpenReceipt(true);
  };

  const handleCloseReceipt = () => {
    setOpenReceipt(false);
  };

  const [userId, setUserId] = useState("");
  const [pin, setPin] = useState();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (!user) {
      return;
    }

    setUserId(user.user_id);
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Bill Payment">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-default">
                  <div class="card-header">
                    <h3 class="card-title">Bill Payment</h3>
                    <div class="card-tools">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                          history.push("/user/bill_payment_report");
                        }}
                      >
                        Report
                      </button>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6 mx-auto">
                      <form ref={formRef} autoComplete="off">
                        <div class="card-body">
                          <div style={{ textAlign: "right" }}>
                            <img
                              src="/images/bharat_bill_pay_logo.png"
                              alt="bbps-logo"
                              width={80}
                            />
                          </div>

                          <div class="form-group row pt-3">
                            <div class="col-sm">
                              <Autocomplete
                                fullWidth
                                options={operatorList}
                                getOptionLabel={(option) =>
                                  option.operator_name
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select Operator"
                                  />
                                )}
                                onChange={(e, v) => {
                                  // console.log(JSON.stringify(v));
                                  setSelectedOperator(v ? v : null);
                                }}
                              />
                            </div>
                          </div>

                          <div className="mb-2">
                            <BarLoader
                              color="#42ba96"
                              loading={fetchOperatorLoading}
                              width="100%"
                            />
                          </div>

                          {selectedOperator &&
                            selectedOperator.params.map((e, key) => (
                              <div class="form-group row" key={key}>
                                <div class="col-sm">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder={e.label}
                                    defaultValue={amount}
                                    onChange={(e, v) => {
                                      var val = e.target.value;
                                      selectedOperator.params[key]["value"] =
                                        val;
                                    }}
                                  />
                                </div>
                              </div>
                            ))}

                          {selectedOperator && (
                            <div class="text-center mb-3">
                              <button
                                type="button"
                                class="btn btn-primary"
                                onClick={() => {
                                  //  JSON.stringify(selectedOperator);
                                  billFetch();
                                }}
                              >
                                Continue
                              </button>
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                {/* <RecentTransactions
                  data={data}
                  loading={loading}
                  recentTransactions={recentTransactions}
                /> */}
              </div>
            </div>
          </div>
        </section>
        <BillDetailsDialog
          open={open}
          handleClose={handleClose}
          handleOpen={handleOpen}
          formRef={formRef}
          userId={userId}
          pin={pin}
          setPin={setPin}
          selectedOperator={selectedOperator ? selectedOperator : null}
          billData={billData ? billData : null}
          billPaymentHandler={billPaymentHandler}
          amount={amount}
          setAmount={setAmount}
        />

        <BillReceiptDialog
          open={openReceipt}
          handleClose={handleCloseReceipt}
          handleOpen={handleCloseReceipt}
          data={receiptData}
        />
      </Templete>
    </React.Fragment>
  );
};

export default BillPayment;
