import React, { useState, useEffect, useContext } from "react";
import DataTableComponent, { createTheme } from "react-data-table-component";

import { useHistory } from "react-router-dom";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Templete from "../Templete/Templete";
import AuthService from "../../../services/Admin/AuthService";
import RechargeService from "../../../services/Admin/RechargeService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";
import TxnDetailsDialog from "../Transactions/TxnDetailsDialog";

import "./styles.css";

const LiveRechargeApi = () => {
  const loadingCtx = useContext(LoadingContext);

  const [fromDate, setFromDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [toDate, setToDate] = useState(new Date().toISOString().slice(0, 10));
  const [status, setStatus] = useState();
  const [productId, setProductId] = useState();

  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(100);
  const [page, setPage] = useState();
  const [count, setCount] = useState();
  const [data, setData] = useState();

  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState();

  useEffect(() => {
    fetchData();

    // document.body.setAttribute("style", "background: black;");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchData();
    }, 30000);

    return () => clearInterval(intervalId);
  }, []);

  const fetchData = async () => {
    const token = AuthService.getAccessToken();

    RechargeService.report(
      token,
      fromDate,
      toDate,
      status,
      productId,
      null,
      searchText,
      page,
      size
    )
      .then((responseData) => {
        if (responseData.status !== "SUCCESS") {
          throw new Error(responseData.message);
        }

        const dataResult = responseData.data.result;
        setCount(responseData.data.count);
        setData(dataResult);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      name: "User ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.tbl_user.user_id}</span> <br />
          <small>{row.tbl_user.name}</small>
        </div>
      ),
    },

    {
      name: "Order ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.order_id}</span>
          <br />
          <small>{row.partner_order_id}</small>
        </div>
      ),
    },
    {
      name: "API Name",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.recharge_api ? row.recharge_api.name : "NULL"}</span>
        </div>
      ),
    },
    {
      name: "Operator",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>
            Operator:
            <b style={{ fontWeight: "bold", fontSize: "14px" }}>
              {" "}
              {row.product.name}
            </b>
          </span>
          <br />
          <span>
            Number:
            <b style={{ fontWeight: "bold", fontSize: "14px" }}>
              {" "}
              {row.optional1}
            </b>
          </span>
          <br />
          <span>
            Amount:
            <b style={{ fontWeight: "bold", fontSize: "14px" }}>
              {" "}
              ₹{row.order_amount}
            </b>
          </span>
        </div>
      ),
    },
    {
      name: "Status",
      cell: (row) => (
        <div>
          {(() => {
            if (row.status === "SUCCESS") {
              return (
                <h5>
                  <span class="badge badge-success">SUCCESS</span>
                </h5>
              );
            } else if (row.status === "FAILED") {
              return (
                <h5>
                  <span class="badge badge-danger">FAILED</span>
                </h5>
              );
            } else if (row.status === "PENDING") {
              return (
                <h5>
                  <span class="badge badge-warning">PENDING</span>
                </h5>
              );
            } else if (row.status === "REVERSAL") {
              return (
                <h5>
                  <span class="badge badge-danger">REVERSAL</span>
                </h5>
              );
            } else if (row.status === "REFUND") {
              return (
                <h5>
                  <span class="badge badge-danger">FAILED</span>
                </h5>
              );
            }
          })()}
        </div>
      ),
    },
    {
      name: "Commission",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>User: ₹{row.commercial_amount}</span> <br />
          <span>Admin: ₹{row.admin_c_amount}</span>
        </div>
      ),
    },
    {
      name: "Operator Ref",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span> {row.operator_id ? row.operator_id : "NULL"}</span>
        </div>
      ),
    },
    {
      name: "Response",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.secret_optional2 ? row.secret_optional2 : "NULL"}</span>
        </div>
      ),
    },
    {
      name: "Date Time",
      cell: (row) => (
        <div>
          <span>{row.created_on}</span>
        </div>
      ),
    },
    // {
    //   name: "Action",
    //   cell: (row) => (
    //     <div>
    //       <h6>
    //         <span
    //           class="badge badge-light"
    //           onClick={() => {
    //             apiDisputeRequestHandler(row.id);
    //           }}
    //         >
    //           API Dispute Request
    //         </span>
    //       </h6>
    //       <h6>
    //         <span
    //           class="badge badge-light"
    //           onClick={() => {
    //             apiStatusCheckHandler(row.id);
    //           }}
    //         >
    //           API Status Check
    //         </span>
    //       </h6>
    //     </div>
    //   ),
    // },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <IconButton
            color="primary"
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(event) => {
              handleClick(event);
              setSelectedData(row);
            }}
          >
            <MenuOpenIcon />
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleMenuClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                apiDisputeRequestHandler(selectedData.id);
              }}
            >
              API Dispute Request
            </MenuItem>
            <MenuItem
              onClick={() => {
                apiStatusCheckHandler(selectedData.id);
              }}
            >
              API Status Check
            </MenuItem>
          </Menu>

          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => {
              setSelectedData(row);
              setOpen(true);
            }}
          >
            <MenuIcon />
          </IconButton>

          <IconButton
            color="success"
            aria-label="upload picture"
            component="span"
            onClick={async () => {
              const { value: formValues } = await Swal.fire({
                title: "Are you sure to update the status to SUCCESS?",
                html: `
                  <input id="swal-input1" placeholder="Operator ID" value=${row.operator_id} class="swal2-input">
                `,
                focusConfirm: false,
                showCancelButton: true,
                confirmButtonText: `Do Success`,
                confirmButtonColor: "green",
                preConfirm: () => {
                  return [document.getElementById("swal-input1").value];
                },
              });
              if (formValues) {
                updateTxnStatusHandler(row.id, "SUCCESS", formValues[0], null);
              }
            }}
          >
            <DoneOutlineIcon />
          </IconButton>

          <IconButton
            color="error"
            aria-label="upload picture"
            component="span"
            onClick={async () => {
              const { value: formValues } = await Swal.fire({
                title: "Are you sure to update the status to FAILED?",
                html: `
                <input id="swal-input2" placeholder="Remark" class="swal2-input">
                `,
                focusConfirm: false,
                showCancelButton: true,
                confirmButtonText: `Do Failure`,
                confirmButtonColor: "red",
                preConfirm: () => {
                  return [document.getElementById("swal-input2").value];
                },
              });
              if (formValues) {
                updateTxnStatusHandler(row.id, "FAILED", null, formValues[0]);
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const updateTxnStatusHandler = async (id, status, operator_id, remark) => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await RechargeService.updateTxnStatus(
        token,
        id,
        status,
        operator_id,
        remark
      );

      const status1 = responseData.status;
      const message = responseData.message;

      if (status1 === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const apiDisputeRequestHandler = async (id) => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await RechargeService.apiDisputeRequest(token, id);

      const status1 = responseData.status;
      const message = responseData.message;
      const log = responseData.data ? responseData.data.log : null;

      //  alert(message);
      Swal.fire({
        title: message,
        text: log ? JSON.stringify(log) : "null",
      });
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const apiStatusCheckHandler = async (id) => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await RechargeService.apiStatusCheck(token, id);

      const status1 = responseData.status;
      const message = responseData.message;
      const log = responseData.data ? responseData.data.log : null;

      Swal.fire({
        title: message,
        text: log ? JSON.stringify(log) : "null",
      });
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: "#000000",
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#000000",
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#000000",
        },
      },
    },
  };

  return (
    <div className="live-recharge-table">
      <DataTableComponent
        noHeader={true}
        columns={columns}
        data={data}
        progressPending={loading}
        highlightOnHover
        pagination
        paginationServer
        paginationTotalRows={count}
        paginationPerPage={size}
        onChangePage={(page) => {
          setPage(page);
          fetchData();
        }}
        paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500, 1000]}
        onChangeRowsPerPage={(size) => {
          setSize(size);
          fetchData();
        }}
        customStyles={customStyles}
        dense
      />

      <TxnDetailsDialog setOpen={setOpen} open={open} fData={selectedData} />
    </div>
  );
};

export default LiveRechargeApi;
