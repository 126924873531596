import React, { useRef, useContext } from "react";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";

import AuthService from "../../../../services/User/AuthService";
import UtiitslPANCardService from "../../../../services/User/UtiitslPANCardService";
import LoadingContext from "../../../../store/loading-context";
import HttpError from "../../../../utill/HttpError";
import { useHistory } from "react-router-dom";

import $ from "jquery";
window.$ = $;

const OrderReviewDialog = (props) => {
  const loadingCtx = useContext(LoadingContext);

  const pinRef = useRef();

  const payNowHandler = async () => {
    const pin = pinRef.current.value;

    if (!pin) {
      Swal.fire("Opps!", "Invalid PIN", "error");
      return;
    }

    props.handleClose();

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const userId = await AuthService.getUserID();

      const responseData = await UtiitslPANCardService.buyCoupon(
        token,
        userId,
        pin,
        props.quantity
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        props.handleClose();
        Swal.fire("Success!", message, "success");
      } else {
        props.handleOpen();
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      props.handleOpen();
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Modal show={props.open} onHide={props.handleClose} size="lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Order Review</h4>
            <button
              type="button"
              class="close"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div>
              <li class="list-group-item">
                <b>Quantity</b> <b class="float-right">{props.quantity}</b>
              </li>
              <li class="list-group-item">
                <b>Order Amount</b>{" "}
                <b class="float-right">
                  ₹{props.data && props.data.order_amount}
                </b>
              </li>

              <li class="list-group-item">
                <b>Commission</b>{" "}
                <b class="float-right">
                  ₹{props.data && props.data.commercial_amount}
                </b>
              </li>

              <li class="list-group-item">
                <b>TDS on Commission</b>{" "}
                <b class="float-right">
                  ₹{props.data && props.data.tds_amount}
                </b>
              </li>

              <li class="list-group-item">
                <b>Payable Amount</b>{" "}
                <b class="float-right">
                  ₹{props.data && props.data.total_price}
                </b>
              </li>
            </div>

            <div class="input-group mb-3 mt-4">
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock"></span>
                </div>
              </div>
              <input
                type="text"
                ref={pinRef}
                class="form-control"
                placeholder="Transaction PIN"
                maxLength={4}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-default"
              onClick={props.handleClose}
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              onClick={payNowHandler}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default OrderReviewDialog;
