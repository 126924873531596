import React, { useRef, useContext } from "react";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";

import $ from "jquery";
window.$ = $;

const DatePickerDialog = (props) => {
  return (
    <React.Fragment>
      <Modal show={props.open} onHide={props.handleClose} size="md">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Date Picker</h4>
            <button
              type="button"
              class="close"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="col-sm-12">
              <div class="form-group">
                <input
                  type="date"
                  class="form-control"
                  placeholder="From Date"
                  onChange={(e) => {
                    props.setFromDate(e.target.value);
                  }}
                  defaultValue={props.fromDate}
                />
              </div>
            </div>

            <div class="col-sm-12">
              <div class="form-group">
                <input
                  type="date"
                  class="form-control"
                  placeholder="To Date"
                  onChange={(e) => {
                    props.setToDate(e.target.value);
                  }}
                  defaultValue={props.toDate}
                />
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-default"
              onClick={props.handleClose}
            >
              Close
            </button>
            <button type="button" class="btn btn-primary" onClick={props.fetch}>
              Ok
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default DatePickerDialog;
