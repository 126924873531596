import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import "./style.css";

const EgovServicesBox = () => {
  const history = useHistory();

  const [services, setServices] = useState([
    {
      name: "NSDL PAN (EKYC)",
      image: "/images/nsdl-logo.png",
      href: "/user/nsdl_pancard/EKYC",
    },
    {
      name: "NSDL PAN (SCAN BASED)",
      image: "/images/nsdl-logo.png",
      href: "/user/nsdl_pancard/ESIGN",
    },
    {
      name: "UTI PAN (REVAMP)",
      image: "/images/uti-logo.png",
      href: "/user/utiitsl_revamp_pancard",
    },
    {
      name: "UTI PAN (E-WALLET)",
      image: "/images/uti-logo.png",
      href: "/user/utiitsl_pancard",
    },
  ]);

  const navigate = (path) => {
    history.push(path);
  };

  return (
    <React.Fragment>
      <div className="dash-serviceBoxTitle">e-Governance</div>
      <div className="row pt-2">
        {services.map((value, key) => (
          <div className="col-sm-2">
            <div className="card">
              <div
                className="dash-serviceBox"
                onClick={() => {
                  navigate(value.href);
                }}
              >
                <div className="service-image">
                  <a href>
                    <img
                      className="img-thumbnail rounded-circle avatar-md"
                      alt="logo"
                      src={value.image}
                    />
                  </a>
                </div>
                <div className="service-content">
                  <h6 style={{ color: "black" }}>{value.name}</h6>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

export default EgovServicesBox;
