import React, { useRef, useContext, useState, useEffect } from "react";
import Swal from "sweetalert2";
import ReactSelect from "react-select";
import classnames from "classnames";
import {
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ListGroup,
  ListGroupItem,
  Label,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
} from "reactstrap";
import BarLoader from "react-spinners/BarLoader";
import Select from "react-select";
import Templete from "../../Templete/Templete";

import AuthService from "../../../../services/User/AuthService";
import RechargeService from "../../../../services/User/RechargeService";
import LoadingContext from "../../../../store/loading-context";
import HttpError from "../../../../utill/HttpError";
import { useHistory } from "react-router-dom";

import OrderReviewDialog from "./OrderReviewDialog";
import ViewPlansDialog from "./ViewPlansDialog";

const RechargePlan = (props) => {
  const circle = props.circle;

  function handleCircleChange(e) {
    //   alert(e.target.value);
    props.setCircle(e.target.value);
    fetchPlans(e.target.value);
  }

  const data = props.plansData;

  const circles = [
    { value: "Andhra Pradesh", label: "Andhra Pradesh" },
    { value: "Assam", label: "Assam" },
    { value: "Bihar / Jharkhand", label: "Bihar / Jharkhand" },
    { value: "Chennai", label: "Chennai" },
    { value: "Delhi NCR", label: "Delhi NCR" },
    { value: "Gujarat", label: "Gujarat" },
    { value: "Haryana", label: "Haryana" },
    { value: "Himachal Pradesh", label: "Himachal Pradesh" },
    { value: "Jammu Kashmir", label: "Jammu Kashmir" },
    { value: "Karnataka", label: "Karnataka" },
    { value: "Kerala", label: "Kerala" },
    { value: "Kolkata", label: "Kolkata" },
    {
      value: "Madhya Pradesh / Chhattisgarh",
      label: "Madhya Pradesh / Chhattisgarh",
    },
    { value: "Maharashtra", label: "Maharashtra" },
    { value: "Mumbai", label: "Mumbai" },
    { value: "North East", label: "North East" },
    { value: "Orissa", label: "Orissa" },
    { value: "Punjab", label: "Punjab" },
    { value: "Rajasthan", label: "Rajasthan" },
    { value: "Tamil Nadu", label: "Tamil Nadu" },
    { value: "UP East", label: "UP East" },
    { value: "UP West", label: "UP West" },
    { value: "West Bengal", label: "West Bengal" },
  ];

  //const valuesArray = circles.map((circle) => circle.value);

  const loadingCtx = useContext(LoadingContext);

  const [loading, setIsLoading] = useState(false);

  useEffect(() => {
    if (circle) {
      fetchPlans(circle);
    }
  }, [circle, props.operator]);

  const fetchPlans = async (circle) => {
    const operator = props.operator ? props.operator.label : null;

    if (!operator) {
      return;
    }

    if (!circle) {
      return;
    }

    const found = props.prepaidOperator.find((e) => operator === e.label);

    if (!found) {
      return;
    }

    props.handleSetPlansData();
    setIsLoading(true);

    try {
      const token = AuthService.getAccessToken();

      const responseData = await RechargeService.fetchPlans(
        token,
        operator,
        circle
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        props.handleSetPlansData(responseData.data);
        // Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      props.handleOpen();
      Swal.fire("Opps!", errMsg, "error");
    }

    setIsLoading(false);
  };

  // Border Top Nav
  const [topBorderTab, settopBorderTab] = useState("1");
  const topBordertoggle = (tab) => {
    if (topBorderTab !== tab) {
      settopBorderTab(tab);
    }
  };

  //   const customStyles = {
  //     control: (base) => ({
  //       ...base,
  //       height: 50,
  //       minHeight: 50,
  //     }),
  //   };

  return (
    <React.Fragment>
      <div
        class="card card-default"
        style={{ height: "525px", overflow: "auto", width: "100%" }}
      >
        <div class="card-header">
          <h3 class="card-title">Plans</h3>
          <div class="card-tools">
            <button
              class="btn btn-sm"
              style={{ backgroundColor: "transparent" }}
              onClick={() => {
                fetchPlans(props.circle);
              }}
            >
              <i class="fas fa-sync-alt"></i>
            </button>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 mx-auto">
            <div class="card-body">
              <div className="row">
                <div className="col-md-6">
                  <ReactSelect
                    //  styles={customStyles}
                    options={props.prepaidOperator}
                    onChange={(e) => {
                      props.setOperator(e);
                    }}
                    value={props.operator}
                    // defaultValue={{
                    //   value: 1,
                    //   label: "Airtel",
                    //   image: "images/airtel_logo.png",
                    // }}
                    formatOptionLabel={(operator) => (
                      <div>
                        <img
                          src={operator.image}
                          alt="logo-image"
                          height={30}
                        />
                        <span style={{ paddingLeft: "5px" }}>
                          {operator.label}
                        </span>
                      </div>
                    )}
                  />
                  <small className="mt-2">
                    Please note that plans are available for mobile operators
                    only.
                  </small>
                </div>

                <div
                  className="col-md-6"
                  onChange={(e) => {
                    handleCircleChange(e);
                  }}
                >
                  <select className="form-control">
                    <option value={props.circle}>
                      {props.circle ? props.circle : "Select Circle"}
                    </option>
                    {circles &&
                      circles.map((e, key) => {
                        return (
                          <option key={key} value={e.value}>
                            {e.label}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>

              {loading && (
                <div className="pt-4">
                  <BarLoader color="#42ba96" loading={loading} width="100%" />
                </div>
              )}

              {data && (
                <div className="pt-4">
                  <Nav
                    tabs
                    className="nav nav-tabs nav-justified nav-border-top nav-border-top-success mb-3"
                  >
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({ active: topBorderTab === "1" })}
                        onClick={() => {
                          topBordertoggle("1");
                        }}
                      >
                        Unlimited
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({ active: topBorderTab === "2" })}
                        onClick={() => {
                          topBordertoggle("2");
                        }}
                      >
                        Talktime
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({ active: topBorderTab === "3" })}
                        onClick={() => {
                          topBordertoggle("3");
                        }}
                      >
                        Data
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({ active: topBorderTab === "4" })}
                        onClick={() => {
                          topBordertoggle("4");
                        }}
                      >
                        SMS
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({ active: topBorderTab === "5" })}
                        onClick={() => {
                          topBordertoggle("5");
                        }}
                      >
                        Roaming
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({ active: topBorderTab === "6" })}
                        onClick={() => {
                          topBordertoggle("6");
                        }}
                      >
                        FRC
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({ active: topBorderTab === "7" })}
                        onClick={() => {
                          topBordertoggle("7");
                        }}
                      >
                        STV
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={topBorderTab} className="text-muted">
                    <TabPane tabId="1" id="nav-border-justified-home">
                      <table class="table table-hover table-responsive">
                        <tbody>
                          {data &&
                            data.unlimited.map((value, key) => (
                              <tr>
                                <td>
                                  <button
                                    type="button"
                                    class="btn btn-info btn-circle btn-sm"
                                    onClick={() => {
                                      props.handleSetAmount(value.price);
                                    }}
                                  >
                                    ₹{value.price}
                                  </button>
                                </td>
                                <td>
                                  <span class="text-muted font-weight-bolder d-block font-size-sm">
                                    {value.description}
                                  </span>
                                  <span>Validity: {value.validity}</span>
                                </td>
                                <td class="pl-0 text-center">
                                  <a
                                    class="btn btn-outline-primary btn-sm"
                                    onClick={() => {
                                      props.handleSetAmount(value.price);
                                    }}
                                  >
                                    Choose
                                  </a>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </TabPane>

                    <TabPane tabId="2" id="nav-border-justified-profile">
                      <table class="table table-hover table-responsive">
                        <tbody>
                          {data &&
                            data.talktime.map((value, key) => (
                              <tr>
                                <td>
                                  <button
                                    type="button"
                                    class="btn btn-info btn-circle btn-sm"
                                    onClick={() => {
                                      props.handleSetAmount(value.price);
                                    }}
                                  >
                                    ₹{value.price}
                                  </button>
                                </td>
                                <td>
                                  <span class="text-muted font-weight-bolder d-block font-size-sm">
                                    {value.description}
                                  </span>
                                  <span>Validity: {value.validity}</span>
                                </td>
                                <td class="pl-0 text-center">
                                  <a
                                    class="btn btn-outline-primary btn-sm"
                                    onClick={() => {
                                      props.handleSetAmount(value.price);
                                    }}
                                  >
                                    Choose
                                  </a>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </TabPane>

                    <TabPane tabId="3" id="nav-border-justified-messages">
                      <table class="table table-hover table-responsive">
                        <tbody>
                          {data &&
                            data.data.map((value, key) => (
                              <tr>
                                <td>
                                  <button
                                    type="button"
                                    class="btn btn-info btn-circle btn-sm"
                                    onClick={() => {
                                      props.handleSetAmount(value.price);
                                    }}
                                  >
                                    ₹{value.price}
                                  </button>
                                </td>
                                <td>
                                  <span class="text-muted font-weight-bolder d-block font-size-sm">
                                    {value.description}
                                  </span>
                                  <span>Validity: {value.validity}</span>
                                </td>
                                <td class="pl-0 text-center">
                                  <a
                                    class="btn btn-outline-primary btn-sm"
                                    onClick={() => {
                                      props.handleSetAmount(value.price);
                                    }}
                                  >
                                    Choose
                                  </a>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </TabPane>

                    <TabPane tabId="4" id="nav-border-justified-messages">
                      <table class="table table-hover table-responsive">
                        <tbody>
                          {data &&
                            data.sms.map((value, key) => (
                              <tr>
                                <td>
                                  <button
                                    type="button"
                                    class="btn btn-info btn-circle btn-sm"
                                    onClick={() => {
                                      props.handleSetAmount(value.price);
                                    }}
                                  >
                                    ₹{value.price}
                                  </button>
                                </td>
                                <td>
                                  <span class="text-muted font-weight-bolder d-block font-size-sm">
                                    {value.description}
                                  </span>
                                  <span>Validity: {value.validity}</span>
                                </td>
                                <td class="pl-0 text-center">
                                  <a
                                    class="btn btn-outline-primary btn-sm"
                                    onClick={() => {
                                      props.handleSetAmount(value.price);
                                    }}
                                  >
                                    Choose
                                  </a>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </TabPane>

                    <TabPane tabId="5" id="nav-border-justified-messages">
                      <table class="table table-hover table-responsive">
                        <tbody>
                          {data &&
                            data.roaming.map((value, key) => (
                              <tr>
                                <td>
                                  <button
                                    type="button"
                                    class="btn btn-info btn-circle btn-sm"
                                    onClick={() => {
                                      props.handleSetAmount(value.price);
                                    }}
                                  >
                                    ₹{value.price}
                                  </button>
                                </td>
                                <td>
                                  <span class="text-muted font-weight-bolder d-block font-size-sm">
                                    {value.description}
                                  </span>
                                  <span>Validity: {value.validity}</span>
                                </td>
                                <td class="pl-0 text-center">
                                  <a
                                    class="btn btn-outline-primary btn-sm"
                                    onClick={() => {
                                      props.handleSetAmount(value.price);
                                    }}
                                  >
                                    Choose
                                  </a>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </TabPane>

                    <TabPane tabId="6" id="nav-border-justified-messages">
                      <table class="table table-hover table-responsive">
                        <tbody>
                          {data &&
                            data.frc.map((value, key) => (
                              <tr>
                                <td>
                                  <button
                                    type="button"
                                    class="btn btn-info btn-circle btn-sm"
                                    onClick={() => {
                                      props.handleSetAmount(value.price);
                                    }}
                                  >
                                    ₹{value.price}
                                  </button>
                                </td>
                                <td>
                                  <span class="text-muted font-weight-bolder d-block font-size-sm">
                                    {value.description}
                                  </span>
                                  <span>Validity: {value.validity}</span>
                                </td>
                                <td class="pl-0 text-center">
                                  <a
                                    class="btn btn-outline-primary btn-sm"
                                    onClick={() => {
                                      props.handleSetAmount(value.price);
                                    }}
                                  >
                                    Choose
                                  </a>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </TabPane>

                    <TabPane tabId="7" id="nav-border-justified-messages">
                      <table class="table table-hover table-responsive">
                        <tbody>
                          {data &&
                            data.stv.map((value, key) => (
                              <tr>
                                <td>
                                  <button
                                    type="button"
                                    class="btn btn-info btn-circle btn-sm"
                                    onClick={() => {
                                      props.handleSetAmount(value.price);
                                    }}
                                  >
                                    ₹{value.price}
                                  </button>
                                </td>
                                <td>
                                  <span class="text-muted font-weight-bolder d-block font-size-sm">
                                    {value.description}
                                  </span>
                                  <span>Validity: {value.validity}</span>
                                </td>
                                <td class="pl-0 text-center">
                                  <a
                                    class="btn btn-outline-primary btn-sm"
                                    onClick={() => {
                                      props.handleSetAmount(value.price);
                                    }}
                                  >
                                    Choose
                                  </a>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </TabPane>
                  </TabContent>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RechargePlan;
