import common from "../../utill/Common";

class UtiitslPANCardService {
  generateLoginUrl = async (token) => {
    const url = common.baseUrl + "user/utiitsl_psa/get_redirect_url";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  agentOnboard = async (token) => {
    const url = common.baseUrl + "user/uti_pancard/agent_onboard";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  agentDetails = async (token) => {
    const url = common.baseUrl + "user/uti_pancard/agent_details";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  fetchCommercial = async (token, quantity) => {
    const url = common.baseUrl + "user/uti_pancard/fetch_commercial";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ quantity }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  buyCoupon = async (token, userId, pin, quantity) => {
    const url = common.baseUrl + "user/uti_pancard/buy_coupon";

    const basicAuth =
      "Basic " + Buffer.from(userId + ":" + pin).toString("base64");

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
        Authorization: basicAuth,
      },
      body: JSON.stringify({ quantity }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new UtiitslPANCardService();
