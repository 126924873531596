import React, { useRef, useContext } from "react";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";

import AuthService from "../../../../services/User/AuthService";
import RechargeService from "../../../../services/User/RechargeService";
import LoadingContext from "../../../../store/loading-context";
import HttpError from "../../../../utill/HttpError";
import { useHistory } from "react-router-dom";

import $ from "jquery";
window.$ = $;

const BillDetailsDialog = (props) => {
  const loadingCtx = useContext(LoadingContext);

  const selectedOperator = props.selectedOperator;
  const billData = props.billData;

  return (
    <React.Fragment>
      <Modal show={props.open} onHide={props.handleClose} size="lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Bill Details</h4>
            <img
              src="/images/bharat_bill_pay_logo.png"
              alt="bbps-logo"
              width={80}
            />
          </div>
          <div class="modal-body">
            {selectedOperator && billData && (
              <div>
                <div class="table-responsive">
                  <table class="table table-striped table-primary">
                    <tr>
                      <th>Biller Name:</th>
                      <td>{selectedOperator.operator_name}</td>
                    </tr>
                    {billData.customer_ref_id && (
                      <tr>
                        <th>Customer REF ID:</th>
                        <td>{billData.customer_ref_id}</td>
                      </tr>
                    )}
                    {billData.customer_name && (
                      <tr>
                        <th>Customer Name:</th>
                        <td>{billData.customer_name}</td>
                      </tr>
                    )}
                    {billData.bill_date && (
                      <tr>
                        <th>Bill Date:</th>
                        <td>{billData.bill_date}</td>
                      </tr>
                    )}
                    {billData.bill_period && (
                      <tr>
                        <th>Bill Period:</th>
                        <td>{billData.bill_period}</td>
                      </tr>
                    )}
                    {billData.bill_number && (
                      <tr>
                        <th>Bill Number:</th>
                        <td>{billData.bill_number}</td>
                      </tr>
                    )}
                    {billData.due_date && (
                      <tr>
                        <th>Due Date:</th>
                        <td>{billData.due_date}</td>
                      </tr>
                    )}
                    {billData.bill_amount && (
                      <tr>
                        <th>Bill Amount:</th>
                        <td>{billData.bill_amount}</td>
                      </tr>
                    )}
                    {billData.bill_net_amount && (
                      <tr>
                        <th>Bill Net Amount:</th>
                        <td>{billData.bill_net_amount}</td>
                      </tr>
                    )}
                  </table>
                </div>
              </div>
            )}

            {billData && billData["accept_part_pay"] == true && (
              <div class="input-group mb-3 mt-3">
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-rupee-sign"></span>
                  </div>
                </div>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Amount"
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  }}
                  onChange={(e, v) => {
                    props.setAmount(e.target.value);
                  }}
                />
              </div>
            )}

            <div class="input-group mb-3 mt-3">
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock"></span>
                </div>
              </div>
              <input
                type="text"
                class="form-control"
                placeholder="Transaction PIN"
                maxLength={4}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                onChange={(e, v) => {
                  props.setPin(e.target.value);
                }}
              />
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-default"
              onClick={props.handleClose}
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              onClick={props.billPaymentHandler}
            >
              Pay Now
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default BillDetailsDialog;
