import React, { useRef, useState, useContext, useEffect } from "react";
import Swal from "sweetalert2";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import CompanyService from "../../../services/Admin/CompanyService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";
import CompanyDetails from "./SettingsTabs/CompanyDetails";
import EmailSettings from "./SettingsTabs/EmailSettings";
import BrandingSettings from "./SettingsTabs/BrandingSettings";
import StorageSettings from "./SettingsTabs/StorageSettings";
import PaymentSettings from "./SettingsTabs/PaymentSettings";
import WhatsappSettings from "./SettingsTabs/WhatsappSettings";
import SmsSettings from "./SettingsTabs/SmsSettings";

const Settings = () => {
  const loadingCtx = useContext(LoadingContext);

  const [tabs, setTabs] = useState([
    { name: "Company Details" },
    { name: "Branding Settings" },
    { name: "Email Settings" },
    { name: "SMS Settings" },
    { name: "Whatsapp Settings" },
    { name: "Payment Settings" },
    { name: "Storage Settings" },
  ]);

  const [activeTab, setActiveTab] = useState("Company Details");

  const [data, setData] = useState();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await CompanyService.fetchCompanyDetails(token);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        const data = responseData.data;
        setData(data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Settings">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div className="col-md-2">
                <div class="card card-primary">
                  <div class="row">
                    <div class="col-sm">
                      <div
                        class="nav flex-column nav-pills"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        {tabs.map((e, index) => {
                          return (
                            <a
                              className={`nav-link ${
                                activeTab === e.name ? "active" : ""
                              }`}
                              id={index}
                              data-toggle="pill"
                              role="tab"
                              onClick={() => {
                                setActiveTab(e.name);
                              }}
                            >
                              {e.name}
                            </a>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-10">
                <div class="card card-default">
                  <div class="card-header">
                    <h3 class="card-title">{activeTab}</h3>
                  </div>

                  <div class="row">
                    <div class="col-md-12 mx-auto">
                      <div class="card-body">
                        {activeTab === "Company Details" && (
                          <CompanyDetails data={data ? data : null} />
                        )}
                        {activeTab === "Email Settings" && (
                          <EmailSettings data={data ? data : null} />
                        )}
                        {activeTab === "Branding Settings" && (
                          <BrandingSettings data={data ? data : null} />
                        )}
                        {activeTab === "Whatsapp Settings" && (
                          <WhatsappSettings data={data ? data : null} />
                        )}
                        {activeTab === "Storage Settings" && (
                          <StorageSettings data={data ? data : null} />
                        )}
                        {activeTab === "Payment Settings" && (
                          <PaymentSettings data={data ? data : null} />
                        )}
                        {activeTab === "SMS Settings" && (
                          <SmsSettings data={data ? data : null} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default Settings;
