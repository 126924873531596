import React, { useRef, useContext } from "react";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";

import AuthService from "../../../../services/User/AuthService";
import RechargeService from "../../../../services/User/RechargeService";
import LoadingContext from "../../../../store/loading-context";
import HttpError from "../../../../utill/HttpError";
import { useHistory } from "react-router-dom";

import $ from "jquery";
window.$ = $;

const BillReceiptDialog = (props) => {
  const loadingCtx = useContext(LoadingContext);

  const data = props.data;

  return (
    <React.Fragment>
      <Modal show={props.open} onHide={props.handleClose} size="lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Bill Payment Receipt</h4>
            <img src="/images/b_assured_logo.png" alt="bbps-logo" width={50} />
          </div>
          <div class="modal-body">
            {data && (
              <div>
                <div class="table-responsive">
                  <table class="table table-striped table-bordered">
                    <tr>
                      <th>Biller Name</th>
                      <td>{data.operator_name}</td>
                    </tr>
                    <tr>
                      <th>Customer REF ID</th>
                      <td>{data.number}</td>
                    </tr>
                    <tr>
                      <th>Amount</th>
                      <td style={{ fontWeight: "bold", fontSize: "16px" }}>
                        ₹{data.amount}
                      </td>
                    </tr>
                    <tr>
                      <th>Order ID</th>
                      <td>{data.order_id}</td>
                    </tr>
                    <tr>
                      <th>Status</th>
                      <td>
                        {data.status} [{data.description}]
                      </td>
                    </tr>
                    <tr>
                      <th>BBPS Ref ID</th>
                      <td>{data.operator_ref_id}</td>
                    </tr>
                    <tr>
                      <th>Timestamp</th>
                      <td>{data.timestamp}</td>
                    </tr>
                  </table>
                </div>
              </div>
            )}
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-default"
              onClick={props.handleClose}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default BillReceiptDialog;
