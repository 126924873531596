import React, { useState, useEffect, useContext } from "react";
import { Base64 } from "js-base64";
import $ from "jquery";

import LoadingContext from "../../../../store/loading-context";

const UtiitslPaymentProcess = () => {
  const [url, setUrl] = useState(
    "https://www.myutiitsl.com/panonlineservices/paymentProcess.jsp"
  );

  const [transID, setTransID] = useState();
  const [transStatus, setTransStatus] = useState();
  const [applicationNo, setApplicationNo] = useState();
  const [UTITSLTransID, setUTITSLTransID] = useState();
  const [transAmt, setTransAmt] = useState();

  const loadingCtx = useContext(LoadingContext);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const data = params.get("data");

    if (!data) {
      window.location.reload();
      return;
    }

    const decryptedData = JSON.parse(Base64.decode(data));

    //  alert(decryptedData.transID);

    setTransID(decryptedData.transID);
    setTransStatus(decryptedData.transStatus);
    setApplicationNo(decryptedData.applicationNo);
    setUTITSLTransID(decryptedData.UTITSLTransID);
    setTransAmt(decryptedData.transAmt);

    await new Promise((resolve) => setTimeout(resolve, 3000));

    $("#form").submit();
  };

  return (
    <React.Fragment>
      <center style={{ paddingTop: "40px" }}>
        <p>Processing Your Payment</p>
        <p>Please wait ...</p>
      </center>
      <form method="POST" action={url} id="form">
        <input type="hidden" name="transID" value={transID} />
        <input type="hidden" name="transStatus" value={transStatus} />
        <input type="hidden" name="applicationNo" value={applicationNo} />
        <input type="hidden" name="UTITSLTransID" value={UTITSLTransID} />
        <input type="hidden" name="transAmt" value={transAmt} />

        {/* <input type="submit" /> */}
      </form>
    </React.Fragment>
  );
};

export default UtiitslPaymentProcess;
