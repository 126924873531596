import React, { useState, useEffect, useContext } from "react";
import Swal from "sweetalert2";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { useHistory } from "react-router-dom";

import AuthService from "../../../../services/User/AuthService";
import NsdlPANCardService from "../../../../services/User/NsdlPANCardService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "./../../../../store/loading-context";
import SubsOrderReviewDialog from "./SubsOrderReviewDialog";

const Subscription = (props) => {
  const loadingCtx = useContext(LoadingContext);

  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState();

  const subscriptionHandler = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await NsdlPANCardService.getSubscriptionFees(token);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setAmount(responseData.data.fees);
        setOpen(true);
      } else {
        if (message == "You have already subscribied") {
          const user = JSON.parse(localStorage.getItem("user"));
          user.subscription_status = true;
          localStorage.setItem("user", JSON.stringify(user));
          props.handleSubscribed();
        } else {
          Swal.fire("Opps!", message, "error");
        }
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <Row className="justify-content-center mt-2">
        <Col sm={6}>
          <div className="text-center plan-btn">
            <h5>
              You have not yet subscribed in order to access the e-KYC based
              NSDL PAN service.
            </h5>
            <Button
              color="primary"
              className="mt-3 mb-2"
              onClick={subscriptionHandler}
            >
              Subscribe Now
            </Button>
          </div>
        </Col>
      </Row>

      <SubsOrderReviewDialog
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
        amount={amount}
        handleSubscribed={props.handleSubscribed}
      />
    </div>
  );
};

export default Subscription;
