import React, { useRef, useContext, useState, useEffect } from "react";
import Swal from "sweetalert2";
import upiqr from "upiqr";

import Templete from "../../Templete/Templete";

import AuthService from "../../../../services/User/AuthService";
import UtiitslPANCardService from "../../../../services/User/UtiitslPANCardService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "../../../../store/loading-context";
import BuyCoupon from "./BuyCoupon";

const UtiitslRevampPANCard = () => {
  const loadingCtx = useContext(LoadingContext);

  const [agentId, setAgentId] = useState();
  const [isOnboarded, setIsOnboarded] = useState(true);

  useEffect(() => {
    agentDetails();
  }, []);

  const agentDetails = async () => {
    const agentId = localStorage.getItem("uti_revamp_agent_id");

    // if (agentId) {
    //   setAgentId(agentId);
    //   setIsOnboarded(true);
    //   return;
    // }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await UtiitslPANCardService.agentDetails(token);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        localStorage.setItem("uti_revamp_agent_id", responseData.data.agent_id);
        setAgentId(responseData.data.agent_id);
        setIsOnboarded(true);
      } else {
        if (message == "NO_DATA_FOUND") {
          setIsOnboarded(false);
        } else {
          Swal.fire("Opps!", message, "error");
        }
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const agentOnboard = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await UtiitslPANCardService.agentOnboard(token);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        localStorage.setItem("uti_revamp_agent_id", responseData.data.agent_id);
        setAgentId(responseData.data.agent_id);
        setIsOnboarded(true);

        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="UTIITSL PAN Card (Revamp)">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">UTIITSL PAN Card (Revamp)</h3>
                  </div>

                  <div className="row justify-content-center m-4">
                    <div className="col-sm-12">
                      {!isOnboarded && (
                        <div class="row justify-content-center">
                          <div class="col-md-12">
                            <div class="card-body">
                              <div className="text-center plan-btn">
                                <h5>
                                  You have not yet registered with the UTIITSL
                                  Revamp module (e-KYC/Scan Based) to access PAN
                                  services. Registration is free of cost.
                                </h5>
                                <button
                                  className="btn btn-primary mt-3 mb-2"
                                  onClick={agentOnboard}
                                >
                                  Register Now
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {isOnboarded && <BuyCoupon agentId={agentId} />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default UtiitslRevampPANCard;
